<template>
  <div class="index">
    <div class="info">
      <el-row style="width: 100%; height: 100%">
        <div class="nav">
          <div class="flex item_centent">
            <div class="date flex">
              <img src="../assets/img/date.png" alt="" />
              <div class="time">
                <span class="span1">{{ date.time + ":" }}</span>
                <span class="span1"> {{ date.branch + ":" }}</span>
                <span class="span2"> {{ date.second }}</span>
              </div>
              <div class="times">
                <div>{{ date.week }}</div>
                <div>
                  {{ date.year + "." }}{{ date.month + "." }}{{ date.day }}
                </div>
              </div>
            </div>
            <!-- logo -->
            <div class="logo">
              <img class="logo_img" src="../assets/img/title.png" alt="" />
              <div class="">鑫推富</div>
            </div>
            <!-- 信息 -->
            <div class="user flex">
              <div class="txt">电话卡</div>
              <div class="span"></div>
              <div class="txt site_name">靓号</div>
              <img src="../assets/img/userinfo.png" alt="用户头像" />
            </div>
          </div>
        </div>
        <el-col class="el-col" :xs="12" :sm="12" :md="12" :lg="7" :xl="7">
          <div class="info_cloumn info_left info_between">
            <!-- 第一个面板 -->
            <div class="panel panel1">
              <div class="panel1_item">
                <p ref="usernum" :data-time="time" :data-value="Info.usernum">
                  0
                </p>
              </div>
              <div class="panel1_item">
                <p ref="userdl" :data-time="time" :data-value="Info.userdl">
                  0
                </p>
              </div>
              <div class="panel1_item">
                <p
                  ref="productall"
                  :data-time="time"
                  :data-value="Info.productall"
                >
                  0
                </p>
              </div>
              <div class="panel1_item">
                <div class="dot" style="background-color: #44d570" />
                <h1>客户总数</h1>
              </div>
              <div class="panel1_item">
                <div class="dot" style="background-color: #39c6aa" />
                <h1>代理总数</h1>
              </div>
              <div class="panel1_item">
                <div class="dot" style="background-color: #d2484a" />
                <h1>套餐总数</h1>
              </div>
            </div>
            <!-- 第三个面板 -->
            <div class="panel panel3">
              <!-- <div class="pie">
                <div class="chart"></div>
              </div> -->
              <div class="bar">
                <div class="chart"></div>
              </div>
            </div>
            <!-- 第二个面板 -->
            <div class="panel panel2">
              <div class="title">
                <!-- <h1>开卡订单</h1>
                <p>|</p>
                <p>鑫推富</p> -->
              </div>
              <div class="table">
                <table>
                  <thead class="thead">
                    <tr>
                      <th>下单姓名</th>
                      <th>邀请人</th>
                      <th>下单状态</th>
                      <th>下单时间</th>
                    </tr>
                  </thead>
                  <tbody class="tbody scroll-content">
                    <tr v-for="item in panel2Data" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.user.name }}</td>
                      <td v-if="item.status == 1">待处理</td>
                      <td v-if="item.status == 2">待发货</td>
                      <td v-if="item.status == 3">已发货</td>
                      <td v-if="item.status == 4">已完成</td>
                      <td v-if="item.status == 5">失败</td>
                      <td v-if="item.status == 6">取消</td>
                      <td v-if="item.status == 7">退款</td>
                      <td v-if="item.numberStatus == 3">已完成</td>
                      <td v-if="item.numberStatus == 2">已完成</td>
                      <td v-else-if="item.orderStatus == 2">待充值</td>
                      <td v-else-if="item.orderStatus == 3">已发货</td>
                      <td v-else-if="item.orderStatus == 4">失败</td>
                      <td>{{ item.createtime }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="0" :sm="0" :md="0" :lg="10" :xl="10">
          <div class="info_cloumn">
            <div class="map">
              <div class="chart"></div>
              <div class="map1"></div>
              <div class="map2"></div>
              <div class="map3"></div>
            </div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="7" :xl="7">
          <div class="info_cloumn info_right info_between">
            <!-- 第五个面板 -->
            <div class="panel panel5 panel52 panel66">
              <div class="panel5_item">
                <p
                  ref="daynewuser"
                  :data-time="time"
                  :data-value="Info.daynewuser"
                >
                  0
                </p>
              </div>
              <div class="panel5_item">
                <p
                  ref="daynewdaili"
                  :data-time="time"
                  :data-value="Info.daynewdaili"
                >
                  0
                </p>
              </div>
              <div class="panel5_item">
                <div class="dot" style="background-color: #d2484a" />
                <h1>今日新增客户</h1>
              </div>
              <div class="panel5_item">
                <div class="dot" style="background-color: rgb(234, 234, 48)" />
                <h1>今日新增代理</h1>
              </div>
            </div>
            <!-- 第五个面板 -->
            <div class="panel panel5 panel51 panel66">
              <div class="panel5_item">
                <p ref="shiye" :data-time="time" :data-value="Info.shiye">0</p>
              </div>
              <div class="panel5_item">
                <p ref="mingyun1" :data-time="time" :data-value="Info.mingyun1">
                  0
                </p>
              </div>
              <div class="panel5_item">
                <p ref="mingyun2" :data-time="time" :data-value="Info.mingyun2">
                  0
                </p>
              </div>
              <div class="panel5_item">
                <p ref="mingyun3" :data-time="time" :data-value="Info.mingyun3">
                  0
                </p>
              </div>
              <div class="panel5_item">
                <div class="dot" style="background-color: #39c6aa" />
                <h1>事业奖池</h1>
              </div>
              <div class="panel5_item">
                <div class="dot" style="background-color: #d2484a" />
                <h1>命运一阶奖池</h1>
              </div>
              <div class="panel5_item">
                <div class="dot" style="background-color: rgb(234, 234, 48)" />
                <h1>命运二阶奖池</h1>
              </div>
              <div class="panel5_item">
                <div class="dot" style="background-color: #2986ff" />
                <h1>命运三阶奖池</h1>
              </div>
            </div>
            <div class="panel panel77">
              <div class="head"></div>
              <div class="List">
                <div class="item" v-for="(item, index) in List" :key="index">
                  <div class="avter">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <div class="nickname">
                    {{ item.username }}
                  </div>
                  <div class="count">邀请人数: {{ item.count }}/人</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Nav from "./Nav.vue";
import Swich from "./Swich.vue";

import * as echarts from "echarts";
import "../assets/js/china";
// import {CountTo} from 'vue-count-to';
export default {
  data() {
    return {
      date: {
        year: "",
        month: "",
        day: "",
        time: "",
        branch: "",
        second: "",
        week: "",
      },
      edituserinfo: false,
      modifypassword: false,
      list: [
        {
          name: "退出登录",
        },
      ],
      passwordlist: {
        oldpassword: "",
        newpassword: "",
        newpasswords: "",
      },
      siteuserlist: "",
      nickname: "",
      // 个人中心
      userinfo: {
        account: "",
        nickname: "",
        mobile: "",
        mailbox: "",
        remarks: "",
        startdate: "",
        frequency: "",
        xiaoxi: "",
      },
      //
      mapData: [
        // { name: "北京", value: 216 },
        // { name: "上海", value: 100 },
        // { name: "广州", value: 260 },
      ],
      // 第一个面板数据
      panel1Data: {
        // 订单总数
        ordersTotal: 0,
        // 产品总数
        productsTotal: 0,
        // 代理总数
        agentTotal: 0,
      },
      // 列表数据(第二个面板数据)
      panel2Data: [],
      // 第三个面板数据
      panel3Data: [
        // { value: 20, name: "云南" },
        // { value: 26, name: "北京" },
        // { value: 24, name: "山东" },
        // { value: 25, name: "河北" },
        // { value: 20, name: "江苏" },
        // { value: 25, name: "浙江" },
        // { value: 30, name: "深圳" },
        // { value: 42, name: "广东" },
      ],
      // 第四个面板数据
      panel4Data: {
        //x轴数据
        xData: ["广东", "上海", "北京", "河南", "安徽", "浙江", "福建"],
        // y轴数据
        yData: [200, 300, 300, 900, 1500, 1200, 600],
      },
      // 第五个面板数据
      panel5Data: {
        todayOrders: 0,
        todayCommission: 0,
      },
      // 第六个面板数据
      panel6Data: {
        xData: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        yData: [
          [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
          [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
        ],
      },
      // 第七个面板数据
      panel7Data: {},
      // 第八个面板数据
      panel8Data: 60,
      // 第九个面板数据
      panel9Data: {},
      Info: {
        orderall: 0,
      },
      time: 1,
      List: [],
    };
  },
  created() {
    this.getuserinfo();
    setInterval(() => {
      this.getdate();
    }, 1000);
  },
  mounted() {
    let that = this;
    that.GetInfo();
    // 折线图渲染（第六个面板）
    // (function () {
    //   // 基于准备好的dom，初始化echarts实例
    //   var myChart = echarts.init(document.querySelector(".line .chart"));

    //   // (1)准备数据

    //   // 2. 指定配置和数据
    //   var option = {
    //     color: ["#00f2f1", "#ed3f35"],
    //     tooltip: {
    //       // 通过坐标轴来触发
    //       trigger: "axis",
    //     },
    //     legend: {
    //       // 距离容器10%
    //       right: "10%",
    //       // 修饰图例文字的颜色
    //       textStyle: {
    //         color: "#4c9bfd",
    //       },
    //       // 如果series 里面设置了name，此时图例组件的data可以省略
    //       // data: ["邮件营销", "联盟广告"]
    //     },
    //     grid: {
    //       top: "20%",
    //       left: "3%",
    //       right: "4%",
    //       bottom: "3%",
    //       show: true,
    //       borderColor: "#012f4a",
    //       containLabel: true,
    //     },

    //     xAxis: {
    //       type: "category",
    //       boundaryGap: false,
    //       data: that.panel6Data.xData,
    //       // 去除刻度
    //       axisTick: {
    //         show: false,
    //       },
    //       // 修饰刻度标签的颜色
    //       axisLabel: {
    //         color: "rgba(255,255,255,.7)",
    //       },
    //       // 去除x坐标轴的颜色
    //       axisLine: {
    //         show: false,
    //       },
    //     },
    //     yAxis: {
    //       type: "value",
    //       // 去除刻度
    //       axisTick: {
    //         show: false,
    //       },
    //       // 修饰刻度标签的颜色
    //       axisLabel: {
    //         color: "rgba(255,255,255,.7)",
    //       },
    //       // 修改y轴分割线的颜色
    //       splitLine: {
    //         lineStyle: {
    //           color: "#012f4a",
    //         },
    //       },
    //     },
    //     series: [
    //       {
    //         name: "预期佣金",
    //         type: "line",
    //         stack: "总量",
    //         // 是否让线条圆滑显示
    //         smooth: true,
    //         data: that.panel6Data.yData[0],
    //       },
    //       {
    //         name: "实际佣金",
    //         type: "line",
    //         stack: "总量",
    //         smooth: true,
    //         data: that.panel6Data.yData[1],
    //       },
    //     ],
    //   };
    //   // 3. 把配置和数据给实例对象
    //   myChart.setOption(option);
    //   window.addEventListener("resize", function () {
    //     myChart.resize();
    //   });
    // })();

    // 仪表盘渲染（第8个面板）
    // (function () {
    //   var myChart = echarts.init(document.querySelector(".gauge .chart"));
    //   var option = {
    //     tooltip: {
    //       formatter: "{a} <br/>{b} : {c}%",
    //     },
    //     series: [
    //       {
    //         name: "进度",
    //         type: "gauge",
    //         splitLine: {
    //           show: false,
    //         },
    //         axisTick: { show: false },
    //         axisLabel: { show: false },
    //         progress: {
    //           show: true,
    //         },
    //         title: {
    //           color: "#fff",
    //         },
    //         detail: {
    //           valueAnimation: true,
    //           formatter: "{value}",
    //           color: "#FFF",
    //         },
    //         textStyle: {
    //           color: "#fff",
    //         },
    //         data: [
    //           {
    //             value: that.panel8Data,
    //           },
    //         ],
    //       },
    //     ],
    //   };
    //   myChart.setOption(option);
    // })();
  },
  methods: {
    // 获取当前的时间
    getdate() {
      //+ "-"
      let date = new Date();
      let Y = date.getFullYear();
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var weekday = new Array(7);
      weekday[0] = "星期天";
      weekday[1] = "星期一";
      weekday[2] = "星期二";
      weekday[3] = "星期三";
      weekday[4] = "星期四";
      weekday[5] = "星期五";
      weekday[6] = "星期六";
      let eekday = weekday[date.getDay()];
      //
      this.date.year = Y;
      this.date.month = M;
      this.date.day = D;
      this.date.time = h;
      this.date.branch = m;
      this.date.second = s;
      this.date.week = eekday;
    },
    getxiaoxidown(item) {
      this.userinfo.xiaoxi = item.name;
      this.xiaoxidown = false;
    },
    //
    getjournaldown(item) {
      this.getcancellogin();
    },
    getuserinfo() {
      if (!this.siteuserlist) {
        let info = JSON.parse(localStorage.getItem("info"));
        // console.log(info)
        this.siteuserlist = info;
      }
    },
    Count5Info() {
      setTimeout(()=>{
        this.GetInfo(false)
      },10000)
    },
    // 退出登录
    getcancellogin() {
      localStorage.removeItem("info");
      this.$router.push({ path: "/login" });
    },
    async GetInfo(type = true) {
      let { data } = await this.$axios({
        url: this.$Api.info,
        method: "post",
        header: {
          "Content-Type": " multipart/form-data",
        },
      });
      this.Info = data.data;
      this.panel2Data = data.data.orderlist.data;
      if (type) {
        this.numberGrow(this.$refs.usernum, this.Info.usernum);
        this.numberGrow(this.$refs.userdl, this.Info.userdl);
        this.numberGrow(this.$refs.productall, this.Info.productall);
        //
        this.numberGrow(this.$refs.daynewuser, this.Info.daynewuser);
        this.numberGrow(this.$refs.daynewdaili, this.Info.daynewdaili);
        //
        this.numberGrow(this.$refs.shiye, 666);
        this.numberGrow(this.$refs.mingyun1, this.Info.mingyun1);
        this.numberGrow(this.$refs.mingyun2, this.Info.mingyun2);
        this.numberGrow(this.$refs.mingyun3, this.Info.mingyun3);
      }
      //
      // data.data.stat.agent_stat.forEach((item) => {
      //   item.value = item.count;
      //   item.name = item.city;
      // });
      // this.panel3Data = data.data.stat.agent_stat;
      let xData = [];
      let yData = [];
      data.data.stat.agent_stat.forEach((item) => {
        xData.push(item.city);
        yData.push(item.count);
      });
      this.panel4Data = {
        xData,
        yData,
      };
      this.GetInfo3();
      //
      let mapData = [];
      data.data.stat.normal_stat.forEach((item) => {
        let name = item.city.replace(/省/g, "");
        let obj = {
          // city: item.city,
          // name: name,
          // city: item.city,
          name: "",
          value: item.count,
        };
        if (item.city == "河北省") {
          obj.name = "石家庄";
        }
        if (item.city == "山西省") {
          obj.name = "太原";
        }
        if (item.city == "辽宁省") {
          obj.name = "沈阳";
        }
        if (item.city == "吉林省") {
          obj.name = "长春";
        }
        if (item.city == "黑龙江省") {
          obj.name = "哈尔滨";
        }
        if (item.city == "江苏省") {
          obj.name = "南京";
        }
        if (item.city == "浙江省") {
          obj.name = "杭州";
        }
        if (item.city == "安徽省") {
          obj.name = "合肥";
        }
        if (item.city == "福建省") {
          obj.name = "福州";
        }
        if (item.city == "江西省") {
          obj.name = "南昌";
        }
        if (item.city == "山东省") {
          obj.name = "济南";
        }
        if (item.city == "河南省") {
          obj.name = "郑州";
        }
        if (item.city == "湖北省") {
          obj.name = "武汉";
        }
        if (item.city == "湖南省") {
          obj.name = "长沙";
        }
        if (item.city == "广东省") {
          obj.name = "广州";
        }
        if (item.city == "四川省") {
          obj.name = "成都";
        }
        if (item.city == "贵州省") {
          obj.name = "贵阳";
        }
        if (item.city == "陕西省") {
          obj.name = "西安";
        }
        if (item.city == "甘肃省") {
          obj.name = "兰州";
        }
        if (item.city == "海南省") {
          obj.name = "海口";
        }
        if (item.city == "云南省") {
          obj.name = "昆明";
        }
        if (item.city == "北京市") {
          obj.name = "北京";
        }
        if(item.city=='重庆市'){
          obj.name = '重庆'
        }
        if (item.city == "上海市") {
          obj.name = "上海";
        }
        if(item.city=='天津市'){
          obj.name = "天津";
        }
        if(item.city=='青海省'){
          obj.name = "西宁";
        }
        if (item.city == "内蒙古自治区") {
          obj.name = "呼和浩特";
        }
        if(item.city=='广西壮族自治区'){
          obj.name = "南宁";
        }
        if(item.city=='宁夏回族自治区'){
          obj.name = "银川";
        }
        if(item.city=='新疆维吾尔自治区'){
          obj.name = "乌鲁木齐";
        }
        mapData.push(obj);
      });
      // console.log(mapData);
      this.mapData = mapData;
      this.$nextTick(() => {
        this.GetInfo5();
      });
      //
      this.List = data.data.stat.subordinate_stat;
      this.Count5Info()
    },
    GetInfo5() {
      let that = this;
      //  地图渲染
      (function () {
        // 1. 实例化对象
        var myChart = echarts.init(document.querySelector(".map .chart"));
        // 2. 指定配置和数据
        var geoCoordMap = {
          上海: [121.4648, 31.2891],
          东莞: [113.8953, 22.901],
          东营: [118.7073, 37.5513],
          中山: [113.4229, 22.478],
          临汾: [111.4783, 36.1615],
          临沂: [118.3118, 35.2936],
          丹东: [124.541, 40.4242],
          丽水: [119.5642, 28.1854],
          乌鲁木齐: [87.9236, 43.5883],
          佛山: [112.8955, 23.1097],
          保定: [115.0488, 39.0948],
          兰州: [103.5901, 36.3043],
          包头: [110.3467, 41.4899],
          北京: [116.4551, 40.2539],
          北海: [109.314, 21.6211],
          南京: [118.8062, 31.9208],
          南宁: [108.479, 23.1152],
          南昌: [116.0046, 28.6633],
          南通: [121.1023, 32.1625],
          厦门: [118.1689, 24.6478],
          台州: [121.1353, 28.6688],
          合肥: [117.29, 32.0581],
          呼和浩特: [111.4124, 40.4901],
          咸阳: [108.4131, 34.8706],
          哈尔滨: [127.9688, 45.368],
          唐山: [118.4766, 39.6826],
          嘉兴: [120.9155, 30.6354],
          大同: [113.7854, 39.8035],
          大连: [122.2229, 39.4409],
          天津: [117.4219, 39.4189],
          太原: [112.3352, 37.9413],
          威海: [121.9482, 37.1393],
          宁波: [121.5967, 29.6466],
          宝鸡: [107.1826, 34.3433],
          宿迁: [118.5535, 33.7775],
          常州: [119.4543, 31.5582],
          广州: [113.5107, 23.2196],
          廊坊: [116.521, 39.0509],
          延安: [109.1052, 36.4252],
          张家口: [115.1477, 40.8527],
          徐州: [117.5208, 34.3268],
          德州: [116.6858, 37.2107],
          惠州: [114.6204, 23.1647],
          成都: [103.9526, 30.7617],
          扬州: [119.4653, 32.8162],
          承德: [117.5757, 41.4075],
          拉萨: [91.1865, 30.1465],
          无锡: [120.3442, 31.5527],
          日照: [119.2786, 35.5023],
          昆明: [102.9199, 25.4663],
          杭州: [119.5313, 29.8773],
          枣庄: [117.323, 34.8926],
          柳州: [109.3799, 24.9774],
          株洲: [113.5327, 27.0319],
          武汉: [114.3896, 30.6628],
          汕头: [117.1692, 23.3405],
          江门: [112.6318, 22.1484],
          沈阳: [123.1238, 42.1216],
          沧州: [116.8286, 38.2104],
          河源: [114.917, 23.9722],
          泉州: [118.3228, 25.1147],
          泰安: [117.0264, 36.0516],
          泰州: [120.0586, 32.5525],
          济南: [117.1582, 36.8701],
          济宁: [116.8286, 35.3375],
          海口: [110.3893, 19.8516],
          淄博: [118.0371, 36.6064],
          淮安: [118.927, 33.4039],
          深圳: [114.5435, 22.5439],
          清远: [112.9175, 24.3292],
          温州: [120.498, 27.8119],
          渭南: [109.7864, 35.0299],
          湖州: [119.8608, 30.7782],
          湘潭: [112.5439, 27.7075],
          滨州: [117.8174, 37.4963],
          潍坊: [119.0918, 36.524],
          烟台: [120.7397, 37.5128],
          玉溪: [101.9312, 23.8898],
          珠海: [113.7305, 22.1155],
          盐城: [120.2234, 33.5577],
          盘锦: [121.9482, 41.0449],
          石家庄: [114.4995, 38.1006],
          福州: [119.4543, 25.9222],
          秦皇岛: [119.2126, 40.0232],
          绍兴: [120.564, 29.7565],
          聊城: [115.9167, 36.4032],
          肇庆: [112.1265, 23.5822],
          舟山: [122.2559, 30.2234],
          苏州: [120.6519, 31.3989],
          莱芜: [117.6526, 36.2714],
          菏泽: [115.6201, 35.2057],
          营口: [122.4316, 40.4297],
          葫芦岛: [120.1575, 40.578],
          衡水: [115.8838, 37.7161],
          衢州: [118.6853, 28.8666],
          西宁: [101.4038, 36.8207],
          西安: [109.1162, 34.2004],
          贵阳: [106.6992, 26.7682],
          连云港: [119.1248, 34.552],
          邢台: [114.8071, 37.2821],
          邯郸: [114.4775, 36.535],
          郑州: [113.4668, 34.6234],
          鄂尔多斯: [108.9734, 39.2487],
          重庆: [107.7539, 30.1904],
          金华: [120.0037, 29.1028],
          铜川: [109.0393, 35.1947],
          银川: [106.3586, 38.1775],
          镇江: [119.4763, 31.9702],
          长春: [125.8154, 44.2584],
          长沙: [113.0823, 28.2568],
          长治: [112.8625, 36.4746],
          阳泉: [113.4778, 38.0951],
          青岛: [120.4651, 36.3373],
          韶关: [113.7964, 24.7028],
        };
        var series = [
          // {
          //   type: 'effectScatter',
          //   coordinateSystem: 'geo',
          //   name: "Beijing",
          //   geoIndex: 0,
          //   symbolSize: function (params) {
          //     return (params[2] / 100) * 15 + 5;
          //   },
          //   itemStyle: {
          //     color: '#b02a02'
          //   },
          //   encode: {
          //     tooltip: 2
          //   },
          //   data: [
          //     [116.4551, 40.2539, 80],
          //     [121.4648, 31.2891, 100],
          //   ]
          // }
        ];
        console.log(that.mapData);
        that.mapData.forEach(function (item, i) {
          series.push({
            type: "effectScatter",
            coordinateSystem: "geo",
            name: item.name,
            // symbolSize: function (val,indexs) {
            //   return val[indexs] * 2;
            // },
            rippleEffect: {
              brushType: "stroke",
            },
            encode: {
              tooltip: 2,
            },
            data: [geoCoordMap[item.name].concat([item.value])],
          });
        });
        var option = {
          tooltip: {
            trigger: "item",
          },

          geo: {
            map: "china",
            label: {
              show: true,
              color: "#fff",
            },
            itemStyle: {
              areaColor: "rgba(43, 196, 243, 0.42)",
              borderColor: "rgba(43, 196, 243, 1)",
              borderWidth: 1,
              emphasis: {
                areaColor: "#2B91B7",
              },
            },
            roam: true,
            //   放大我们的地图
            zoom: 1.2,
          },
          series: series,
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })();
    },
    GetInfo4() {
      let that = this;
      // 饼状图渲染(第三个面板)
      (function () {
        // 1. 实例化对象
        var myChart = echarts.init(document.querySelector(".pie  .chart"));
        // 2. 指定配置项和数据
        var option = {
          legend: {
            top: "90%",
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              color: "rgba(255,255,255,.5)",
              fontSize: "12",
            },
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          // 注意颜色写的位置
          color: [
            "#00f0e5",
            "#006cff",
            "#60cda0",
            "#ed8884",
            "#ff9f7f",
            "#0096ff",
            "#9fe6b8",
            "#32c5e9",
            "#1d9dff",
          ],
          series: [
            {
              name: "代理总数",
              type: "pie",
              // 如果radius是百分比则必须加引号
              radius: ["10%", "70%"],
              center: ["50%", "42%"],
              roseType: "radius",
              data: that.panel3Data,
              // 修饰饼形图文字相关的样式 label对象
              label: {
                fontSize: 10,
              },
              // 修饰引导线样式
              labelLine: {
                // 连接到图形的线长度
                length: 10,
                // 连接到文字的线长度
                length2: 10,
              },
            },
          ],
        };

        // 3. 配置项和数据给我们的实例化对象
        myChart.setOption(option);
        // 4. 当我们浏览器缩放的时候，图表也等比例缩放
        window.addEventListener("resize", function () {
          // 让我们的图表调用 resize这个方法
          myChart.resize();
        });
      })();
    },
    GetInfo3() {
      let that = this;
      // 柱状图渲染（第四个面板）
      (function () {
        // 实例化对象
        var myChart = echarts.init(document.querySelector(".bar .chart"));
        // 指定配置和数据
        var option = {
          color: ["#00f0e5"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          grid: {
            left: "0%",
            top: "10px",
            right: "0%",
            bottom: "4%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: that.panel4Data.xData,
              axisTick: {
                alignWithLabel: true,
              },

              axisLabel: {
                color: "rgba(255,255,255,.6)",
                fontSize: "12",
              },
              axisLine: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: "value",

              axisLabel: {
                color: "rgba(255,255,255,.6)",
                fontSize: "12",
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(255,255,255,.1)",
                  // width: 1,
                  // type: "solid"
                },
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255,255,255,.1)",
                },
              },
            },
          ],
          series: [
            {
              name: "客户总量",
              type: "bar",
              barWidth: "35%",
              data: that.panel4Data.yData,
              itemStyle: {
                borderRadius: 5,
              },
            },
          ],
        };

        // 把配置给实例对象
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })();
    },
    numberGrow(ele, num) {
      // debugger;
      //【这里调速度 1 ，步进值， 通俗地讲，就是每次跳的时候，增加的一个增量】
      let step = parseInt((num * 100) / (this.time * 1000));
      // 设置当前值(这个值是计时器持续运行时，每次页面上显示的跳动值，不是最终的那个具体值)
      let current = 0;
      // 设置开始值
      let start = 0;
      // 设置定时器，用来反复横跳的，哈哈哈
      let t = setInterval(() => {
        // 每次增加一点步进值
        start += step;
        // 开始值大于传过来的的值，说明 到点了，不用 继续横跳了
        if (start > num) {
          clearInterval(t);
          // 把穿过的值赋给start，结束
          start = num;
          // 清掉计时器
          t = null;
        }
        if (start == 0) {
          start = num;
          clearInterval(t);
        }
        // 当前值等于开始值，那就结束
        if (this.value === 0) {
          return;
        }
        current = start;
        // 正则
        // console.log(current)
        ele.innerHTML = current
          .toString()
          .replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, "$1,");
      }, this.time * 100); // 【这里调速度 2， 通俗地讲，这里是页面上，肉眼能看到的跳动频率】
      // 本来想设置成 秒 *1000的，但是实在太慢了，就改成 *100了
    },
  },
  components: {
    Nav,
    Swich,
    // CountTo
  },
};
</script>
<style lang="less" scoped>
@keyframes scroll-left {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
.panel77 {
  .head {
    height: 70px;
  }
}
.List {
  padding: 70px;
  padding-top: 0;
  box-sizing: border-box;
  overflow: auto;
  height: calc(100% - 90px);
  .item {
    padding: 14px 0;
    display: flex;
    align-items: center;
    .avter {
      width: 60px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .nickname {
      color: #00f0e5;
      font-size: 20px;
      margin-left: 20px;
      width: 300px;
    }
    .count {
      color: #00f0e5;
      font-size: 20px;
    }
  }
}
.scroll-content {
  padding-top: 100%;
  animation: scroll-left 10s linear infinite;
}
.info_between {
  justify-content: space-around;
}
.el-col {
  height: calc(100vh - 88px);
}
.index {
  width: 100%;
  background: url("../assets/img/login_back.png") no-repeat center;
  background-size: cover;
  height: 100%;

  .info {
    width: 100%;
    height: 100%;
    // height: calc(100vh - 78px);

    .info_cloumn {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .info_height {
      width: 100%;
      height: calc(100% - 78px);
    }

    .panel {
      // flex: 1;
      margin: 20px;
      background: url("../assets/img/admin_login.png") no-repeat center;
      background-size: cover;
    }

    .fx2 {
      flex: 2;
    }

    .fx3 {
      flex: 3;
    }

    .fx4 {
      flex: 4;
    }
    .panel3 {
      background: url("../assets/img/icon4.png") no-repeat center;
      background-size: 100%;
      height: 35vh;
    }
    .panel4 {
      background: url("../assets/img/icon3.png") no-repeat center;
      background-size: 100%;
      width: 3rem;
      height: 3rem;
      .panel1_item {
        padding: 0.125rem;
        font-size: 0.3rem;
        display: flex;
        align-items: center;
        position: relative;
        // justify-content: center;

        .dot {
          width: 0.125rem;
          height: 0.25rem;
          background-color: #3498db;
          // 不支持简写
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-right: 15px;
        }

        h1 {
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          color: #778be3;
        }

        p {
          font-size: 32px;
          font-weight: 800;
          color: white;
          position: relative;
          top: 0.225rem;
        }
      }
    }
    .panel55 {
      background: url("../assets/img/icon6.png") no-repeat center;
      background-size: 100%;
    }
    .panel66 {
      background: url("../assets/img/icon5.png") no-repeat center;
      background-size: 100%;
      height: 15vh;
      margin: 0 0.25rem;
      position: relative;
    }
    .panel77 {
      background: url("../assets/img/icon9.png") no-repeat center;
      background-size: 100%;
      height: 59vh;
    }

    .panel1 {
      padding: 0 0.625rem 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0.125rem;
      background: url("../assets/img/icon1.png") no-repeat center;
      background-size: 100%;
      height: 10vh;
      margin: 0 0.25rem;

      .panel1_item {
        padding: 0.125rem;
        font-size: 0.3rem;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        top: -0.125rem;

        .dot {
          width: 0.125rem;
          height: 0.25rem;
          background-color: #3498db;
          // 不支持简写
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-right: 15px;
        }

        h1 {
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          color: #778be3;
        }

        p {
          font-size: 32px;
          font-weight: 800;
          color: white;
          position: relative;
          top: 0.225rem;
        }
      }
    }

    .panel2 {
      background: url("../assets/img/icon8.png") no-repeat center;
      box-sizing: border-box;
      padding-left: 50px;
      padding-right: 40px;
      padding-bottom: 40px;
      height: 40vh;
      position: relative;
      background-size: 100%;
      margin: 0 0.25rem;
      .title {
        text-align: center;
        height: 68px;
        position: relative;
        display: inline-block;
      }
      h1 {
        font-size: 1.5vh;
        color: white;
        margin: 5vh 0px 40px 40px;
        display: inline-block;
      }

      p {
        font-size: 1.5vh;
        color: rgb(61, 111, 192);
        margin-left: 40px;
        display: inline-block;
      }

      table {
        border-collapse: collapse;
        width: 100%;
        position: relative;
      }
      .table {
        height: calc(100% - 60px);
        overflow: auto;
        // padding-top: 8px;
        // box-sizing: border-box;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .thead {
        // position: absolute;
        // top: 0;
        background: #022a3e;
        z-index: 33;
        height: 60px;
        width: 100%;
        line-height: 40px;
        top: -10px;
        position: relative;
        tr {
          width: 100%;
        }
        .th {
          &:nth-of-type(1) {
            width: 25%;
            text-align: center;
          }
          &:nth-of-type(2) {
            width: 25%;
            text-align: center;
          }
          &:nth-of-type(3) {
            width: 20%;
            text-align: center;
          }
          &:nth-of-type(4) {
            width: 30%;
            text-align: center;
          }
        }
      }

      th,
      td {
        border: 1px solid transparent;
        padding: 8px;
        font-size: 1vh;
        color: white;
      }

      th {
        background-color: transparent;
        font-weight: bold;
      }

      tr:nth-child(even) {
        background-color: transparent;
      }

      // tr:hover {
      //   background-color: #0088ff29;
      // }
      td {
        &:nth-of-type(1) {
          width: 25%;
          text-align: center;
        }
        &:nth-of-type(2) {
          width: 25%;
          text-align: center;
        }
        &:nth-of-type(3) {
          width: 20%;
          text-align: center;
        }
        &:nth-of-type(4) {
          width: 30%;
          text-align: center;
        }
      }

      @media screen and (max-width: 768px) {
        table {
          width: 100%;
          border: 0;
        }

        th,
        td {
          display: block;
          width: 100%;
          border: none;
          padding: 5px;
        }
        tr {
          border-bottom: 1px solid #ddd;
        }
      }
    }
    .panel51 {
      grid-template-columns: repeat(4, 1fr) !important;
      grid-template-rows: repeat(2, 1fr) !important;
      position: relative;
      margin: 0 0.25rem;
      p {
        top: 40px;
      }
    }
    .panel52 {
      p {
        top: 40px !important;
      }
    }

    .panel5 {
      padding: 0 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: repeat(2, 1fr);
      gap: 10px;

      .panel5_item {
        padding: 10px;
        font-size: 24px;
        display: flex;
        align-items: center;

        .dot {
          width: 10px;
          height: 20px;
          background-color: #3498db;
          // 不支持简写
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-right: 15px;
        }

        h1 {
          display: inline-block;
          font-size: 1.5vh;
          font-weight: 600;
          color: #778be3;
        }

        p {
          font-size: 3vh;
          font-weight: 800;
          color: white;
          position: absolute;
        }
      }
    }

    .panel7 {
      display: flex;
    }

    .map {
      position: relative;
      flex: 3;

      .chart {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        height: 100%;
        width: 100%;
      }

      .map1,
      .map2,
      .map3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6.475rem;
        height: 6.475rem;
        background: url(../assets/img/map.png) no-repeat;
        background-size: 100% 100%;
        opacity: 0.3;
      }

      .map2 {
        width: 8.0375rem;
        height: 8.0375rem;
        background-image: url(../assets/img/lbx.png);
        opacity: 0.6;
        animation: rotate 15s linear infinite;
        z-index: 2;
      }

      .map3 {
        width: 7.075rem;
        height: 7.075rem;
        background-image: url(../assets/img/jt.png);
        animation: rotate1 10s linear infinite;
      }

      @keyframes rotate {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }

        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }

      @keyframes rotate1 {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }

        to {
          transform: translate(-50%, -50%) rotate(-360deg);
        }
      }
    }

    .pie {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .chart {
        width: 90%;
        height: 90%;
      }
    }

    .bar {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .chart {
        width: 90%;
        height: 90%;
      }
    }

    .bar1 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .chart {
        width: 90%;
        height: 90%;
      }
    }

    .gauge {
      width: 60%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto 0;

      .chart {
        width: 100%;
        height: 100%;
      }
    }

    .line {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .chart {
        width: 90%;
        height: 90%;
      }
    }
  }
}
.circle-chart-component-box {
  position: relative;
  --one-color: #ff67a8;
  --two-color: #6eec9b;
  --three-color: #f97b7b;
  --four-color: #4ae4f0;
  --five-color: #f4aa6a;

  .pie-item {
    position: absolute;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    mask: radial-gradient(
      transparent,
      transparent 47px,
      #000 48px,
      #000 48px,
      #000 100%
    );

    &:nth-child(1)::before {
      background: linear-gradient(
        90deg,
        var(--one-color) 50%,
        transparent 51%,
        transparent 100%
      );
    }
    &:nth-child(2)::before {
      background: linear-gradient(
        90deg,
        var(--two-color) 50%,
        transparent 51%,
        transparent 100%
      );
    }
    &:nth-child(3)::before {
      background: linear-gradient(
        90deg,
        var(--three-color) 50%,
        transparent 51%,
        transparent 100%
      );
    }
    &:nth-child(4)::before {
      background: linear-gradient(
        90deg,
        var(--four-color) 50%,
        transparent 51%,
        transparent 100%
      );
    }
    &:nth-child(5)::before {
      background: linear-gradient(
        90deg,
        var(--five-color) 50%,
        transparent 51%,
        transparent 100%
      );
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 24px;
      height: 24px;
      top: 0;
      left: 60px;
      border-radius: 50%;
    }
  }
}
.gauge {
  padding: 20px;
  box-sizing: border-box;
}
.gauge .chart {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background: conic-gradient(
  //   #9ed110,
  //   #50b517,
  //   #179067,
  //   #476eaf,
  //   #9f49ac,
  //   #cc42a2,
  //   #ff3ba7,
  //   #ff5800,
  //   #ff8100,
  //   #feac00,
  //   #ffcc00,
  //   #ede604
  // );
  // border-radius: 50%;
  // mask:radial-gradient(transparent 110px, #000 110px);
  // transition: clip-path .2s;
}
.txt1 {
  margin-right: 40px;
}
.dialogcontent {
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 30px;
  height: 40px;
  align-items: center;
  .name {
    font-size: 18px;
    // color: #01fff1;
  }
  input {
    height: 100%;
    width: 60%;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    // background: #022a3f;
    // border: 1px solid #00f0e5;
    border: 1px solid #b0b0b0;
    color: #000;
    font-size: 16px;
  }
  input::placeholder {
    font-size: 16px;
    color: #000;
    // color: #00f0e5;
  }
}
.dialogflex {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
/deep/ .dialogbutton {
  margin-left: 30px !important;
  color: #fff !important;
  span {
    color: #fff;
  }
}
.drop_down {
  position: absolute;
  top: 56px;
  right: 0;
  width: 120px;
  background: #043248;
  overflow: auto;
  transition: all 1s;
  border: 1px solid #00eee3;
  display: none;
  // flex-direction: column;
  // justify-content: center;
  z-index: 8;
  .item {
    height: 36px;
    display: flex;
    align-items: center;
    color: #00f0e5;
    padding: 0 20px;
    justify-content: center;
    box-sizing: border-box;
    // margin-top: 4px;
    cursor: pointer;
    user-select: none;
  }
  & .item:hover {
    background: #006f7b;
  }
  .drop_border {
    border-bottom: 1px solid #00eee3;
  }
}
.drop_down::-webkit-scrollbar {
  display: none;
}
.nav {
  width: 100%;
  // min-width: 1920px;
  padding: 0 30px 0 50px;
  box-sizing: border-box;
  height: 78px;
  position: relative;
  z-index: 699;
  .flex {
    display: flex;
  }
  .logo {
    width: 1226px;
    height: 56px;
    position: relative;
    // background: url("../assets/img/title.png") center no-repeat;
    // background-size: 100%;
    // border: 1px dashed #b0b0b0;
    .logo_img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    div {
      font-family: "SourceHanSansCN-Bold ";
      font-weight: 400;
      font-size: 30px;
      letter-spacing: 0.1em;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #01fff1;
      height: 100%;
      position: absolute;
      z-index: 2;
      width: 100%;
    }
  }
  .user {
    position: relative;
    height: 56px;
    width: 300px;
    align-items: center;
    justify-content: flex-end;
    .txt {
      font-family: "Microsoft YaHei";
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.1em;
      text-align: left;
      color: #01fff1;
      margin: 0 14px;
    }
    .site_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 120px;
    }
    .span {
      height: 22px;
      width: 2px;
      background: #01fff1;
    }
    img {
      width: 36px;
      height: 36px;
      // border: 1px dashed #b0b0b0;
    }
    &:hover .drop_down {
      display: block;
    }
  }
  .item_centent {
    align-items: center;
    justify-content: space-between;
  }
  .date {
    align-items: center;
    // width: 289px;
    img {
      width: 36px;
      height: 36px;
      // border: 1px dashed #b0b0b0;
      position: relative;
      top: 4px;
    }
    .time {
      margin-left: 4px;
      letter-spacing: 1px;
      position: relative;
      top: 5px;
      .span1 {
        font-family: "Source Han Sans CN Bold";
        font-weight: bold;
        font-size: 36px;
        line-height: 14px;
        text-align: left;
        color: #01fff1;
      }
      .span2 {
        font-family: "Source Han Sans CN Bold";
        font-weight: bold;
        font-size: 26px;
        line-height: 14px;
        text-align: left;
        color: #01fff1;
      }
    }
    .times {
      position: relative;
      left: 20px;
      z-index: 2;
      div {
        font-family: "Source Han Sans CN Medium";
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.05em;
        line-height: 28px;
        text-align: left;
        color: #01fff1;
      }
    }
  }
}
.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    background: rgba(0, 0, 0, 0.3);
  }
  .open_info_width {
    width: 780px !important;
    height: 700px !important;
    .input_input {
      height: 48px !important;
    }
    .open_input {
      .input {
        .input_input {
          width: 300px !important;
        }
      }
    }
  }
  .operation_top {
    margin-top: 80px !important;
  }
  .open_info {
    position: absolute;
    width: 600px;
    height: 560px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: url("../assets/img/open_back.png") no-repeat center;
    background-size: 100%;
    padding: 25px;
    box-sizing: border-box;
    z-index: 7;
    .gb {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 30px;
      height: 30px;
      z-index: 9;
    }
    .gb1 {
      position: absolute;
      right: -5px;
      top: 10px;
      width: 30px;
      height: 30px;
      z-index: 9;
    }
    .open_title {
      width: 100%;
      // background: url("../assets/img/open_title.png") no-repeat center;
      height: 40px;
      text-align: center;
      font-family: "Source Han Sans CN Bold";
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.1em;
      color: #01fff1;
      position: relative;
      .img_back {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
    .open_input {
      margin: 2.02vw auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .input {
        margin-top: 1.26vw;
        position: relative;
        .input_name {
          font-family: "Source Han Sans CN";
          font-weight: 400;
          font-size: 0.8vw;
          text-align: left;
          color: #01fff1;
        }
        .inputtextarea {
          width: 22.67vw !important;
          height: 4.02vw !important;
        }
        .input_input {
          margin-top: 15px;
          width: 17.67vw;
          height: 2.02vw;
          background: #043248;
          border: 1px solid #01fff1;
          border-radius: 6px;
          input {
            width: 100%;
            height: 100%;
            padding-left: 15px;
            box-sizing: border-box;
            font-family: "Source Han Sans CN";
            font-weight: 400;
            font-size: 0.7vw;
            color: #03999d;
          }
          input::placeholder {
            font-family: "Source Han Sans CN";
            font-weight: 400;
            font-size: 0.7vw;
            color: #03999d;
          }
        }
        .input_choice {
          position: relative;
        }
        .input_choice::after {
          content: "";
          position: absolute;
          right: 20px;
          top: 15px;
          width: 0;
          height: 0;
          border: 0.4vw solid transparent;
          border-bottom-color: #00f0e5;
          transform: rotate(180deg);
        }
      }
      .drop_down {
        position: absolute;
        top: 4.04vw;
        right: 0;
        width: 17.67vw;
        min-height: 100%;
        max-height: 200px;
        background: #043248;
        overflow: auto;
        transition: all 1s;
        border: 1px solid #00eee3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 8;
        .item {
          height: 32px;
          display: flex;
          align-items: center;
          color: #00f0e5;
          padding: 0 20px;
          box-sizing: border-box;
        }
        .item_border {
          border-bottom: 1px solid #00eee3;
        }
      }
      .drop_down::-webkit-scrollbar {
        display: none;
      }
    }
    .operation {
      margin: 3.03vw auto 0;
      display: flex;
      justify-content: center;
      .cancel {
        width: 80px;
        height: 40px;
        border-radius: 4px;
        background: transparent;
        border: 1px solid #01fff1;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 40px;
        color: #01fff1;
        cursor: pointer;
        user-select: none;
      }
      .submit {
        margin-left: 15px;
        width: 80px;
        height: 40px;
        border-radius: 4px;
        background: #055f68;
        border: 1px solid #01fff1;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 40px;
        color: #01fff1;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>